import { useStaticQuery, graphql } from 'gatsby';

const useOverviewPage = () => {
  const { pages } = useStaticQuery(graphql`
    query UseStrapiBlogOverviewPage {
      pages: allStrapiPagesPages(
        filter: {
          template: {
            eq: "blogs"
          }
        }
      ) {
        nodes {
          id
          slug
        }
      }
    }
  `);

  // @TODO: we force the first. Should be good enough for now
  return pages.nodes[0];
};

export default useOverviewPage;
