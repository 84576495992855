import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import OriginalPreview from '../components/preview';

const Preview = () => {
  const { posts } = useStaticQuery(graphql`
    query StrapiPostsLimited {
      posts: allStrapiBlogPosts(
        sort: { fields: date, order: DESC }
        limit: 3
      ) {
        nodes {
          id
          ...PostPreview
        }
      }
    }
  `);

  return (
    <Wrapper>
      {posts.nodes.map((post) => (
        <OriginalPreview key={post.id} {...post} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  grid-template-columns: 1fr;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default Preview;
