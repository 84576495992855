import React from 'react';
import styled from 'styled-components';
import Button from 'gatsby-theme-core/src/components/button';
import SearchProvider from 'gatsby-theme-vehicles/src/components/search';
import useSearch from 'gatsby-theme-vehicles/src/components/search/hooks/use-search';
import Options from 'gatsby-theme-vehicles/src/components/search/options';
import useSearchBox from 'gatsby-theme-vehicles/src/hooks/use-search-box';
import staticWhere from 'gatsby-theme-vehicles/src/where';

const SearchBox = () => {
  const { to, totalNumberOfVehicles } = useSearch();

  return (
    <SearchWrapper>
      <SearchTitle>Zoek in aanbod</SearchTitle>
      <SearchContent>
        <Options
          noSpacer
          type="select"
          name="Merk"
          field="make"
          defaultOptionTitle="Alle merken"
          // when there is a make in the staticWhere (Audi)
          required={Boolean(staticWhere.make)} // hack away the default option
          options={staticWhere.make ? [staticWhere.make] : undefined}
        />
        <Options
          noSpacer
          type="select"
          disabledWhenNoOptions
          name="Model"
          field="model"
          defaultOptionTitle="Alle modellen"
        />
        <Options
          noSpacer
          type="select"
          name="Brandstof"
          field="fuel"
          defaultOptionTitle="Alle brandstoffen"
        />
        <Button to={to}>
          {totalNumberOfVehicles ?? '...'} auto&apos;s
        </Button>
      </SearchContent>
    </SearchWrapper>
  );
};

const SearchTitle = styled.h3`
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;
const SearchContent = styled.span`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  @media all and (min-width: ${({ theme }) => theme.breakingpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
  @media all and (min-width: ${({ theme }) => theme.breakingpoints.lg}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
const SearchWrapper = styled.div`
  position: relative;
  background-color: #11323e;
  padding: 1rem;
  margin: -7rem auto 3rem auto;

  @media all and (min-width: ${({ theme }) => theme.breakingpoints.lg}) {
    margin-top: -3.5rem;
  }
`;

const SearchBoxProvider = () => {
  const { filterData } = useSearchBox();
  return (
    <SearchProvider
      filterData={filterData}
      replaceUrl={false}
      limit={1} // we dont care about the vehicles, only get 1
    >
      <SearchBox />
    </SearchProvider>
  );
};

export default SearchBoxProvider;
