import React from 'react';
import styled from 'styled-components';
import { getImage } from 'gatsby-plugin-image';
import useSite from '../hooks/use-site';
import BackgroundImage from './background-image';

const switchImage = (image, defaultImage) => {
  if (image) {
    if (getImage(image.localFile)) {
      return image;
    }
  }
  if (defaultImage) {
    if (getImage(defaultImage.localFile)) {
      return defaultImage;
    }
  }
  return null;
};

const PageImage = ({
  image,
  ...props
}) => {
  const { defaultImage } = useSite();
  const availableImage = switchImage(image, defaultImage);

  if (!availableImage) {
    console.warn('No available image in core PageImage');
    return null;
  }

  return (
    <BackgroundImage image={availableImage} {...props} />
  );
};

export default styled(PageImage)``;
