import React from 'react';
import styled, { css } from 'styled-components';
import Image from './image';

const Overlay = styled.div`
  position: relative;
  ${({ $darken }) => $darken && css`
    background-color: rgba(0, 0, 0, 0.3);
  `}
  ${({ $darker }) => $darker && css`
    background-color: rgba(0, 0, 0, 0.6);
  `}
  ${({ $lighten }) => $lighten && css`
    background-color: rgba(255, 255, 255, 0.3);
  `}
`;

const BackgroundImage = ({
  className,
  src,
  image,
  children,
  darken,
  darker,
  lighten,
  Tag = 'div',
  ...props
}) => (
  <Tag className={className}>
    <Image
      className="background-image"
      image={image}
      {...props}
    />
    <Overlay $darken={darken} $darker={darker} $lighten={lighten}>
      {children}
    </Overlay>
  </Tag>
);

export default styled(BackgroundImage)`
  position: relative;

  .background-image {
    position: absolute !important;
    left:0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;
