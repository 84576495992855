import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Preview from '../components/preview';

const SectionPreviews = ({
  className,
  vehicles = null,
  limit = 4,
  layout = null,
}) => {
  const { vehicles: { nodes } } = useStaticQuery(graphql`
    query VehiclesPreviews {
      vehicles: allStrapiVehiclesVehicles(
        limit: 4,
        filter: { isSold: { ne: true } },
        sort: { fields: createdAt, order: DESC },
      ) {
        nodes {
          ...VehiclePreview
        }
      }
    }
  `);

  return (
    <div className={className}>
      {(vehicles || nodes).slice(0, limit).map((vehicle) => (
        <Preview key={vehicle.id} vehicle={vehicle} layout={layout} />
      ))}
    </div>
  );
};

export default styled(SectionPreviews)`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  grid-template-columns: 1fr;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
