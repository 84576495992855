import { useStaticQuery, graphql } from 'gatsby';

const useSearchBox = () => {
  const { vehicles } = useStaticQuery(graphql`
    fragment SearchBoxFiltersFragment on StrapiVehiclesVehiclesConnection {
      totalCount

      makeModels: group(field: make) {
        field
        fieldValue
        totalCount
        models: group(field: model) {
          field
          fieldValue
          totalCount
        }
      }
      fuels: group(field: fuel) {
        field
        fieldValue
        totalCount
      }
    }

    query VehiclesUseSearchBox {
      vehicles: allStrapiVehiclesVehicles {
        ...SearchBoxFiltersFragment
      }
    }
  `);

  return {
    filterData: vehicles,
  };
};

export default useSearchBox;
