import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Layout from 'gatsby-theme-core/src/layout/layout';
import Container from 'gatsby-theme-core/src/components/container';
import Video from 'gatsby-theme-core/src/components/video';
import PageImage from 'gatsby-theme-core/src/components/page-image';
import useStockPage from 'gatsby-theme-vehicles/src/hooks/use-stock-page';
import useOverviewPage from 'gatsby-theme-blog/src/hooks/use-overview-page';
import BlogPreview from 'gatsby-theme-blog/src/layouts/preview';
import Previews from 'gatsby-theme-vehicles/src/sections/previews';
import SearchBox from '../../components/search-box';
import Ad from '../../components/ad';

export const pageQuery = graphql`
  query EaStrapiHomepage {
    homepage: strapiHomepageHomepage {
      highlightedVehicles {
        ...VehiclePreview
      }

      content: childStrapiHomepageHomepageContent {
        childMarkdownRemark {
          html
        }
      }
      totalAmount
      image {
        url
        name
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              placeholder: BLURRED
              breakpoints: [576, 768, 992, 1200, 2400]
            )
          }
        }
      }
      videos {
        id
        title
        videoCode
      }

      # HomepageHead
      headTitle: title
      headSlug: slug
      headMetaDescription: metaDescription
      headImage: image {
        src: url
        height
        width
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              width: 400
              quality: 80
            )
          }
        }
      }
      # HomepageHead
    }
  }
`;

const Homepage = ({
  data: {
    homepage,
  },
}) => {
  const stockPage = useStockPage();
  const blogsPage = useOverviewPage();
  const previewVehicles = homepage.highlightedVehicles
    .filter(Boolean)
    .filter(({ isSold }) => !isSold) // no sold vehicles
    .slice(0, 5); // limit to 4

  return (
    <Layout {...homepage} homepage>
      <PageImage
        image={homepage.image}
        darker
      >
        <StyledContainer>
          <div>
            <span
              css={`
                font-size: 4rem;
                font-weight: 700;
                color: #f0bb3a;
              `}
            >
              {homepage.totalAmount}+
            </span>
            <h1>exclusieve auto&apos;s te koop</h1>
          </div>
        </StyledContainer>
      </PageImage>
      <Container>
        <SearchBox />
        <Wrapper>
          <FlexTitle>
            <Title>Kippenvel</Title>
            <SmallFlex>
              <Link to={stockPage.slug}>
                Bekijk alles
              </Link>
            </SmallFlex>
          </FlexTitle>
          <Previews vehicles={previewVehicles} />
        </Wrapper>
        <Ad />

        {homepage.videos?.length > 0 && (
          <Wrapper>
            <FlexTitle>
              <Title>Videos</Title>
            </FlexTitle>
            <MultiColumn cols={homepage.videos?.length}>
              {homepage.videos.map(({ id, videoCode, title }) => (
                <Video
                  key={id}
                  source="youtube"
                  video={videoCode}
                  title={title}
                />
              ))}
            </MultiColumn>
          </Wrapper>
        )}

        <Wrapper>
          <FlexTitle>
            <Title>Nieuws</Title>
            <SmallFlex>
              <Link to={blogsPage.slug}>
                Bekijk alles
              </Link>
            </SmallFlex>
          </FlexTitle>
          <BlogPreview />
        </Wrapper>
      </Container>
    </Layout>
  );
};

const MultiColumn = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  @media all and (min-width: ${({ theme }) => theme.breakingpoints.md}) {
    grid-template-columns: repeat(${({ cols }) => (cols > 2 ? cols : 2)}, 1fr);
  }
`;

const FlexTitle = styled.div`
  border-bottom: .25rem solid black;
  padding-bottom: .5rem;
  display: flex;
  align-items: center;
  > * {
    flex-grow: 1;
  }
  margin-bottom: 1rem;
`;

const SmallFlex = styled.div`
  flex-grow: 0;

  > a {
    text-decoration: none;
    font-weight: 400;
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.h2Color};
  padding-bottom: 0;
  margin-bottom: 0;
`;

const StyledContainer = styled(Container)`
  height: 30rem;
  display: flex;
  align-items: center;

  h1 {
    color: white;
    font-size: 1.5rem;
  }
`;

const Wrapper = styled.div`
  padding-bottom: 3rem;
`;

export default Homepage;
