import styled, { css } from 'styled-components';
import Button from './button';

const Content = styled.div.attrs((props) => {
  if (props.$content) {
    if (props.$content.childMarkdownRemark) {
      const { html, excerpt } = props.$content.childMarkdownRemark;
      return {
        dangerouslySetInnerHTML: { __html: html || excerpt },
      };
    }
    return {
      dangerouslySetInnerHTML: { __html: props.$content },
    };
  }
  return {};
})`
  ${({ $withoutBaseStyles }) => !$withoutBaseStyles && css`
    h1 {
      margin: 0 0 1rem 0;
      font-size: 1.5rem;
      color: ${({ theme }) => theme.h1Color};
    }
    h2 {
      margin: 0 0 1rem 0;
      font-size: 1.25rem;
      color: ${({ theme }) => theme.h2Color};
    }
    h2 {
      color: ${({ theme }) => theme.h3Color};
    }
    a:not(${Button}) {
      color: ${({ theme }) => theme.primaryColor};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    p {
      margin: 0 0 1rem 0;
      font-weight: 400;
      line-height: ${({ theme }) => theme.baseLineHeight};
    }
    ul {
      margin: 0 0 1rem 0;
      padding-left: 0;
      line-height: ${({ theme }) => theme.baseLineHeight};
      font-weight: ${({ theme }) => theme.fontWeight};
      list-style: none;

      li {
        position: relative;
        padding-left: 1rem;

        &::before {
          content: "•";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          color: ${({ theme }) => theme.primaryColor};
        }
      }
    }
    ol {
      margin: 0 0 1rem 0;
      line-height: ${({ theme }) => theme.baseLineHeight};
      font-weight: ${({ theme }) => theme.fontWeight};
    }
    address {
      font-style: normal;
      line-height: ${({ theme }) => theme.baseLineHeight};
    }
    img {
      max-width: 100%;
    }
    table {
      line-height: ${({ theme }) => theme.baseLineHeight};
    }
    iframe {
      max-width: 100%;
    }
  `}
`;

export default Content;
