import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Image from 'gatsby-theme-core/src/components/image';
import useSlug from '../../hooks/use-slug';

import Wrapper from './wrapper';
import ContentWrapper from './content-wrapper';
import Excerpt from './excerpt';
import Date from './date';
import Title from './title';
import Link from './link';

export const query = graphql`
  fragment PostPreview on StrapiBlogPosts {
    id
    slug
    title
    excerpt: childStrapiBlogPostsContent {
      childMarkdownRemark {
        excerpt (
          format: PLAIN
          pruneLength: 280
        )
      }
    }
    date(formatString: "DD MMMM YYYY", locale:"nl")
    image {
      id
      url
      name
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 726
            height: 400
            placeholder: BLURRED
          )
        }
      }
    }
    metaDescription
  }
`;

const Preview = ({
  // id,
  slug,
  title,
  excerpt,
  date,
  image,
  className,
  fromOverview,
  // metaDescription,
}) => {
  const postSlug = useSlug(slug);

  return (
    <Wrapper $fullWidth={!image} className={className}>
      {image && (
        <div>
          <Image
            image={image}
            options={['w_726']}
            alt={image.name}
          />
        </div>
      )}

      <ContentWrapper>
        <Date>{date}</Date>
        <Title>{title}</Title>
        <Excerpt>
          <p>
            {excerpt.childMarkdownRemark.excerpt}
          </p>
        </Excerpt>
      </ContentWrapper>
      <Link state={{ fromOverview }} to={postSlug} />
    </Wrapper>
  );
};

export default styled(Preview)``;
